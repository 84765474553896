//DEFAULT IMPORTS
import React, { Suspense, lazy} from 'react';
import ReactDOM from 'react-dom';
import './index.css';

/*
//IMPORT REDUX
import { Provider } from 'react-redux';
import store from './jn-redux/store';
*/

//IMPORT SITEROUTER
//import SiteRouter from './jn-site_router/siteRouter';

//Loading screen stuff
import './loading.scss';
import gif from './jnloading.gif';


const SiteRouter = lazy(() => import('./jn-site_router/siteRouter'));

ReactDOM.render(
  <div>
    <Suspense 
    fallback={<div>
      <div className={`main-show`}>
            <div>
                <img className='gif' src={gif} alt="" />
                <h2 className='txt'>loading...</h2>
            </div>
      </div>
    </div>}>
      <SiteRouter/>
    </Suspense>
    
  </div>,
  document.getElementById('root')
);

